import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Mixpanel } from '../components/mixpanel'

//Parse functions
import { currentUserCheck, loginUser } from '../services/parse-bridge'
import Login from '../components/login'
import Manage from '../components/manage'

//NPM
import Drift from 'react-driftjs'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

const Spinner = styled.div`
  text-align: center;
  /* margin: 15px 0; */
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 80vh;
`
const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Text = styled.h1`
  text-align: center;
  color: #ddd;
  opacity: 0.9;
  margin-bottom: 0.8rem;
`
const Sub = styled.p`
  text-align: center;
  font-size: 0.9rem;
  color: #ccc;
  opacity: 0.95;
`

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loggedIn: false,
      loading: true,
    }
  }

  componentDidMount() {
    currentUserCheck()
      .then(result => {
        console.log('result of current user check in dash', result.data)
        this.setState({ loggedIn: result.data, loading: false })
      })
      .catch(err => {
        console.log('caught an error checking user: ' + err)
        this.setState({ loading: false })
      })
    Mixpanel.track('Land on dashboard')
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <Layout>
          <Manage />
        </Layout>
      )
    }

    return (
      <Layout>
        <div>
          {/* <Drift appId="knr9a4v4xh26" /> */}
          {this.state.loading ? (
            <Spinner>
              <Loader type="Oval" color="#Fc444e" height="40" width="40" />
            </Spinner>
          ) : (
            <Login />
          )}
        </div>
      </Layout>
    )
  }
}

export default Dashboard
